/* eslint-disable @next/next/no-img-element */
import React, { ReactNode } from 'react';

import LWPButton from '../Button/LWPButton';
import Heading1 from '../Typography/Heading1';
import Paragraph from '../Typography/Paragraph';

interface CommonStatusProps {
    children?: ReactNode;
    heading?: string;
    loading?: boolean;
    subHeading?: string;
    headingClass?: string;
    subHeadingClass?: string;
    buttonText?: string;
    onClick?: () => void;
    image?: string;
    image1?: string;
    image2?: string;
    imageAlt?: string;
    className?: string;
    subHeadingDataTest?: string;
}

const CommonStatus = ({
    heading,
    subHeading,
    subHeadingDataTest,
    headingClass,
    subHeadingClass,
    buttonText,
    loading,
    onClick = () => {},
    image = '',
    image1 = '',
    image2 = '',
    imageAlt = '',
    className = '',
    children
}: CommonStatusProps) => {
    return (
        <div className={`common_status_container ${className}`}>
            <div className={`common_status_img`}>
                {image && <img src={image} alt={imageAlt} className="common_status_container_image" />}
                {image1 && <img src={image1} alt={imageAlt} className="common_status_container_image" />}
            </div>
            {heading && <Heading1 text={heading} centerText customClass={headingClass} />}
            {subHeading && <Paragraph dataTest={subHeadingDataTest} text={subHeading} centerText customClass={subHeadingClass} />}
            {image2 && (
                <div className={`common_status_img`}>
                    {image2 && <img src={image2} alt={imageAlt} className="common_status_container_image" />}
                </div>
            )}
            {buttonText && <LWPButton onClick={onClick} loading={loading} btnText={buttonText} size="large" />}
            {children}
        </div>
    );
};

export default CommonStatus;
